import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/layout';
import 'bootstrap';

export default (props) => {
  const { data, location } = props;
  return (
    <Layout data={data} location={location}>
      <div className="text-center">
        <h2 className="page-title">Pay Stub Templates</h2>
        <p>Use the pay stub templates below to calculate payroll. You can fill them out and then print them or just print blanks and fill them in by hand.</p>
      </div>
      <div className="row justify-content-center">
        <div className="m-3">
          <img  src="../images/calculator.png" alt="Template" width="210" height="210" data-mce-src="../images/calculator.png"/>
        </div>
        <div className="text-center m-3 pt-4">
          <h3>Google Drive Pay Stub Template <a href="https://docs.google.com/spreadsheets/d/1zx5R5A6RUMrmRmWyz2I8l4zUdjLOMsTIsz4V1S4qyPQ/edit?usp=sharing" data-mce-href="https://docs.google.com/spreadsheets/d/1zx5R5A6RUMrmRmWyz2I8l4zUdjLOMsTIsz4V1S4qyPQ/edit?usp=sharing">(view)</a></h3>
          <h3>Word Pay Stub Template&nbsp;<a href="/downloads/Paystub-Template-Word.docx" data-mce-href="/downloads/Paystub-Template-Word.docx">(download)</a></h3>
          <h3>Excel Pay Stub Template&nbsp;<a href="/downloads/Paystub-Template-Excel.xlsx" data-mce-href="/downloads/Paystub-Template-Excel.xlsx">(download)</a></h3>
        </div>
      </div>
    </Layout>
  );
}

export const pageQuery = graphql`
  query($slug: String!) {
    site {
      siteMetadata {
        title
        description
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        template
        title
        type
        red_track
        description
      }
    }
  }
`;